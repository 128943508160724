import React, { CSSProperties, useEffect } from 'react';
import { color, images, spacing } from '../Layouts/theme';
import { Container, Row, Col } from 'react-bootstrap';
import {
  BODY_2_EN,
  BODY_2_TH,
  HEADER_2_TH,
  HEADER_TH,
  SPACE_BETWEEN_CONTENT,
} from '../Layouts/styled/main';
import { useTranslation } from 'react-i18next';

const PADDING: CSSProperties = {
  padding: `${spacing[0]}px ${spacing[7]}px`,
};

const Policy = () => {
  const { t } = useTranslation();



  return (
    <main className={'main'}>
      <div style={SPACE_BETWEEN_CONTENT} />
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs="10" md="8" style={{ maxWidth: 1080 }}>
            <div style={{ ...HEADER_TH, textAlign: 'center' }}>
              นโยบายคุ้มครองข้อมูลส่วนบุคคล บริษัท อ้วยอันโอสถ จำกัด (“บริษัท”)
            </div>
            <br />
            <br />
            <div style={BODY_2_TH}>
              บริษัท อ้วยอันโอสถ จำกัด (“บริษัท”)
              ได้ให้ความสำคัญกับการคุ้มครองข้อมูลส่วนบุคคล
              จึงได้จัดทำนโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
              เพื่อวัตถุประสงค์ในการชี้แจงการจัดการข้อมูลส่วนบุคคล
              และเพื่อสร้างความมั่นใจให้แก่เจ้าของข้อมูลส่วนบุคคล
              ว่าบริษัทได้ดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลให้เป็นไปตามที่กฎหมายกำหนด
              <br />
              <br />
              นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้จะอธิบายให้ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลทราบถึงวัตถุประสงค์
              การดำเนินการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล
              ตลอดจนสิทธิตามกฎหมายของเจ้าของข้อมูลส่วนบุคคลที่เกี่ยวข้องกับข้อมูลส่วนบุคคล
              บริษัทจึงใคร่ขอให้เจ้าของข้อมูลส่วนบุคคลศึกษาและทำความเข้าใจนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้อย่างละเอียด
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>1.การเก็บรวบรวมข้อมูลส่วนบุคคล</div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              ทั้งทางตรงและทางอ้อม ดังนี้
              <br />
              <ul style={PADDING}>
                <li>
                  ข้อมูลที่เจ้าของข้อมูลส่วนบุคคลหรือตัวแทนของเจ้าของข้อมูลส่วนบุคคลให้ไว้กับบุคคลดังต่อไปนี้
                  <br />
                  <ul style={PADDING}>
                    <li>บริษัท</li>
                    <li>บริษัทในเครือ</li>
                    <li>บริษัทร่วม และ</li>
                    <li>พันธมิตรทางธุรกิจของบริษัท</li>
                  </ul>
                </li>
                <li>
                  การให้บริการผ่านทางช่องทางต่างๆ ของบริษัท เช่น
                  บริการทางโทรศัพท์
                </li>
                <li>
                  การให้บริการผ่านทางอินเตอร์เน็ต
                  รวมถึงการใช้บริการบนเว็บไซต์ของบริษัท แอปพลิเคชั่น
                  สื่อสังคมออนไลน์
                </li>
                <li>
                  แหล่งข้อมูลอื่นซึ่งไม่ใช่จากเจ้าของข้อมูลส่วนบุคคลโดยตรง เช่น
                  หน่วยงานของรัฐ บริษัทในกลุ่ม คู่สัญญาของบริษัท เป็นต้น
                  ซึ่งบริษัทจะเก็บรวบรวมข้อมูลจากแหล่งอื่นต่อเมื่อได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคลตามที่กฎหมายกำหนด
                  เว้นแต่บริษัทมีความจำเป็นตามกรณีที่กฎหมายอนุญาต
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              2.ประเภทของข้อมูลส่วนบุคคลที่มีการเก็บรวบรวม ใช้
              และ/หรือเปิดเผยข้อมูลส่วนบุคคล
            </div>
            <br />
            <div style={BODY_2_TH}>
              ข้อมูลส่วนบุคคล คือ
              ข้อมูลที่ทำให้สามารถระบุตัวตนของเจ้าของข้อมูลส่วนบุคคลได้
              ไม่ว่าทางตรงหรือทางอ้อม โดยบริษัทจะเก็บรวบรวม ใช้
              และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              ซึ่งประกอบด้วยข้อมูล ดังนี้
              <br />
              <ul style={PADDING}>
                <li>
                  ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล เพศ อายุ วันเกิด สถานภาพสมรส
                  ที่อยู่ อาชีพ
                </li>
                <li>
                  ข้อมูลการติดต่อ เช่น ที่อยู่อาศัย สถานที่ทำงาน หมายเลขโทรศัพท์
                  ไอดีไลน์ ไปรษณีย์อิเล็กทรอนิกส์ (Email address)
                </li>
                <li>
                  ข้อมูลซึ่งระบุตำแหน่งพื้นที่ขณะที่ใช้งานเว็บไซต์
                  กรณีที่เปิดการใช้งาน GPS system
                  ให้ถือว่าเจ้าของข้อมูลส่วนบุคคลให้ความยินยอมกับบริษัทในการเก็บรวบรวมและประมวลผลตำแหน่งพื้นที่ขณะใช้งานดังกล่าว
                  อย่างไรก็ตาม หากต้องการปกปิดข้อมูลนี้
                  เจ้าของข้อมูลส่วนบุคคลสามารถติดตั้งโปรแกรมหรือปิดระบบ GPS
                  บนโทรศัพท์มือถือได้
                </li>
                <li>
                  ข้อมูลเกี่ยวกับอุปกรณ์คอมพิวเตอร์หรือเครื่องมือสื่อสาร เช่น
                  <br />
                  <ul style={PADDING}>
                    <li>
                      ข้อมูลเกี่ยวกับคุกกี้ (Cookies) หมายถึง
                      ข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์
                      เมื่อเข้าเยี่ยมชมเว็บไซต์
                      เรียกดูและโต้ตอบกับเนื้อหาบนเว็บไซต์ของบริษัท
                    </li>
                    <li>
                      ข้อมูลเกี่ยวกับคุกกี้ (Cookies) หมายถึง
                      ข้อมูลที่ได้ส่งจากเว็บไซต์ไปยังเครื่องคอมพิวเตอร์
                      เมื่อเข้าเยี่ยมชมเว็บไซต์
                      เรียกดูและโต้ตอบกับเนื้อหาบนเว็บไซต์ของบริษัท
                    </li>
                    <li>หมายเลขประจำเครื่องคอมพิวเตอร์ (IP address)</li>
                    <li>
                      ชนิดของเว็บเบราว์เซอร์ (Web browser) ที่ใช้ในการเข้าถึง
                    </li>
                    <li>หน้าเว็บ (Web Page) ที่เข้าเยี่ยมชม</li>
                    <li>เว็บไซต์ที่อ้างถึงเว็บของบริษัท</li>
                    <li>
                      ข้อมูลการเชื่อมต่อผ่านช่องทางการเชื่อมต่อของเว็บไซต์
                    </li>
                  </ul>
                </li>
                <li>
                  ข้อมูลการใช้บริการ
                  (ขึ้นอยู่กับบริการที่เจ้าของข้อมูลส่วนบุคคลทำบนเว็บไซต์) เช่น
                  การลงทะเบียนเพื่อรับข่าวสารอิเล็กทรอนิกส์
                  ข้อมูลการแจ้งเบาะแสหรือการร้องเรียน ข้อมูลเกี่ยวกับการสมัครงาน
                  ข้อมูลการติดต่อผ่านทางเว็บไซต์และข้อมูลอื่นใดที่เจ้าของข้อมูลส่วนบุคคลทำกิจกรรมบนเว็บไซต์
                  ซึ่งรวมถึงข้อมูลคอมพิวเตอร์
                  ข้อมูลการเชื่อมต่อผ่านช่องทางการเชื่อมต่อของเว็บไซต์
                </li>
                <li>
                  ข้อมูลอื่นๆ เช่น เสียง ภาพนิ่ง ภาพเคลื่อนไหว ภาพถ่าย
                  ตลอดจนการบันทึกภาพในระบบกล้องวงจรปิด
                </li>
                <li>
                  ข้อมูลอื่นใดที่ถือว่าเป็นข้อมูลส่วนบุคคลภายใต้กฎหมายคุ้มครองข้อมูลส่วนบุคคล
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              3.วัตถุประสงค์ในการเก็บรวบรวมใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล
            </div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทจะดำเนินการเก็บรวบรวมใช้
              และ/หรือเปิดเผยข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
              เพื่อประโยชน์ในการใช้บริการที่บริษัทจัดทำขึ้น
              ตลอดจนเพื่อการปฏิบัติตามกฎหมายที่บริษัท
              และ/หรือเจ้าของข้อมูลส่วนบุคคลต้องปฏิบัติตาม
              และเพื่อวัตถุประสงค์อื่นใดตามที่ระบุในนโยบายฉบับนี้ ดังนี้
              <br />
              <ul style={PADDING}>
                <li>เพื่อการให้บริการตามที่เจ้าของข้อมูลส่วนบุคคลประสงค์</li>
                <li>
                  เพื่อการปรับปรุง พัฒนา การให้บริการ
                  อย่างมีประสิทธิภาพของบริษัท
                </li>
                <li>
                  เพื่อการติดต่อสื่อสาร เช่น การส่งบริการแจ้งเตือน
                  การตอบกลับเมื่อได้รับการติดต่อจากเจ้าของข้อมูลส่วนบุคคล
                </li>
                <li>
                  เพื่อการยืนยันตัวตนของเจ้าของข้อมูลส่วนบุคคล
                  ไม่ว่าเพื่อวัตถุประสงค์ในการสรรหาบุคคล
                  การระบุตัวตนของผู้มาติดต่อ การเข้าออกสำนักงาน
                  ตามมาตรการด้านการรักษาความปลอดภัย
                </li>
                <li>
                  เพื่อวัตถุประสงค์ดังต่อไปนี้
                  เฉพาะกรณีที่เจ้าของข้อมูลส่วนบุคคลให้ความยินยอมโดยชัดแจ้ง
                  <br />
                  <ul style={PADDING}>
                    <li>
                      เพื่อแจ้งข้อมูลข่าวสารต่างๆ ที่เกี่ยวข้องกับบริษัท
                      บริษัทในเครือ และ/หรือบริษัทร่วม รวมถึงการให้บริการ
                      และสิทธิประโยชน์ ที่เกี่ยวข้องกับบริการดังกล่าว
                    </li>
                    <li>
                      เพื่อนำเสนอข้อมูลเกี่ยวกับผลิตภัณฑ์และบริการ
                      และกิจกรรมของบริษัท
                    </li>
                    <li>เพื่อทำการตลาดแบบตรง (Direct Marketing)</li>
                    <li>
                      เพื่อการวิจัยการตลาด
                      การวิเคราะห์พฤติกรรมและความสนใจของเจ้าของข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ทางการส่งเสริมการตลาด
                      รวมถึงการบริการอื่นๆ ที่อาจมีขึ้นในอนาคต
                      ให้สอดคล้องกับความต้องการของเจ้าของข้อมูลส่วนบุคคลมากยิ่งขึ้นเพื่อให้บริการที่ดีขึ้นในการใช้งานเว็บไซต์เพื่อการจำเป็นในการปฏิบัติตามสัญญาซึ่งเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญา
                      หรือเพื่อใช้ในการดำเนินการตามคำขอของเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญา
                    </li>
                  </ul>
                </li>
                <li>เพื่อการปฎิบัติตามกฎหมายของบริษัท</li>
                <li>
                  เพื่อประโยชน์โดยชอบด้วยกฎหมายของบริษัทหรือของบุคคลหรือนิติบุคคลอื่นใด
                </li>
                <li>
                  เพื่อวัตถุประสงค์อื่นที่ได้แจ้งในขณะเก็บข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคล
                  หรือเพื่อวัตถุประสงค์อื่นที่เกี่ยวข้องกับวัตถุประสงค์ข้อหนึ่งข้อใดดังที่ได้กล่าวไว้ข้างต้น
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              4.การรักษาความปลอดภัยของข้อมูลส่วนบุคคล
            </div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยตามมาตรฐานที่กฎหมายกำหนด
              เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข
              หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
              บริษัทจะมีการปรับปรุงและทดสอบระบบเทคโนโลยีของบริษัทอย่างสม่ำเสมอเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลมีความปลอดภัยสูงสุดและน่าเชื่อถือ
              <br />
              <br />
              บริษัทจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยตามมาตรฐานที่กฎหมายกำหนด
              เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข
              หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจหรือโดยมิชอบ
              บริษัทจะมีการปรับปรุงและทดสอบระบบเทคโนโลยีของบริษัทอย่างสม่ำเสมอเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลมีความปลอดภัยสูงสุดและน่าเชื่อถือ
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              5.การเปิดเผย และ/หรือโอนข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก
            </div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทอาจใช้ดุลยพินิจแต่เพียงฝ่ายเดียวในการเปิดเผย และ/หรือ
              โอนข้อมูลส่วนบุคคลให้กับบุคคลที่สามซึ่งกำหนดตัวตนได้แน่นอน
              และมีนโยบายคุ้มครองข้อมูลส่วนบุคคลและมาตรฐานการรักษาความปลอดภัยตามที่กฎหมายกำหนด
              เท่าที่จำเป็นภายใต้ความยินยอมของเจ้าของข้อมูลส่วนบุคคลหรือภายใต้หลักเกณฑ์ที่กฎหมายอนุญาตให้กระทำได้
              รวมถึง บริษัทในเครือ บริษัทร่วม พันธมิตรทางธุรกิจ
              คู่สัญญาของบริษัท หน่วยงานของรัฐ
              และหน่วยงานอื่นใดตามที่กฎหมายกำหนด
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>6.สิทธิของเจ้าของข้อมูลส่วนบุคคล</div>
            <br />
            <div style={BODY_2_TH}>
              <ul style={PADDING}>
                <li>
                  เจ้าของข้อมูลส่วนบุคคลสามารถแจ้งการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล
                  ตลอดจนการเพิกถอนความยินยอมที่ให้บริษัททำการเก็บรวบรวม ใช้
                  และ/หรือเปิดเผยข้อมูลส่วนบุคคลอันเกี่ยวกับข้อมูลส่วนบุคคลของตนได้ตลอดเวลา
                  โดยการใช้สิทธิดังกล่าวต้องเป็นไปตามที่กฎหมายกำหนด
                  และหลักเกณฑ์ที่บริษัทกำหนดไว้ในปัจจุบันหรือที่จะมีการแก้ไขเพิ่มเติมในอนาคต
                  ทั้งนี้
                  การเพิกถอนความยินยอมของเจ้าของข้อมูลส่วนบุคคลอาจส่งผลกระทบต่อการให้บริการบางประการ
                  บริษัทอาจไม่สามารถให้บริการได้อย่างเต็มความสามารถ
                  หรือเจ้าของข้อมูลส่วนบุคคลอาจไม่สามารถใช้บริการหรือสิทธิประโยชน์ของบริษัทได้
                </li>
                <li>
                  การใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลอาจถูกจำกัดภายใต้กฎหมายที่เกี่ยวข้อง
                  และอาจมีบางกรณีที่มีเหตุจำเป็นที่บริษัทอาจปฎิเสธหรือไม่สามารถดำเนินการตามคำขอใช้สิทธิข้างต้นของเจ้าของข้อมูลส่วนบุคคลได้โดยบริษัทจะแจ้งเหตุผลของการปฏิเสธให้ท่านทราบด้วย
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              7. ข้อยกเว้นการคุ้มครองข้อมูลส่วนบุคคล
            </div>
            <br />
            <div style={BODY_2_TH}>
              การดำเนินการต่อข้อมูลส่วนบุคคลดังต่อไปนี้
              ไม่ถือเป็นการฝ่าฝืนนโยบายคุ้มครองข้อมูลส่วนบุคคล
              <br />
              <ul style={PADDING}>
                <li>
                  ข้อมูลส่วนบุคคลที่มีการเปิดเผยต่อสาธารณะอยู่แล้วก่อนที่ท่านจะได้เปิดเผยข้อมูลให้แก่บริษัท
                  หรือเป็นข้อมูลส่วนบุคคลที่ถูกเปิดเผยต่อสาธารณะโดยไม่ได้เกิดจากความผิดของบริษัท
                </li>
                <li>
                  การเปิดเผยข้อมูลส่วนบุคคลโดยได้รับความยินยอมจากท่านไม่ว่าเป็นลายลักษณ์อักษรหรือด้วยวิธีการอื่นใด
                </li>
                <li>
                  การเปิดเผยข้อมูลส่วนบุคคลตามความจำเป็น
                  เพื่อการปฏิบัติตามกฎหมาย คำสั่ง กฎข้อบังคับ คำสั่งศาล
                  หน่วยงานของรัฐ หรือตามความจำเป็นอื่นใด
                </li>
              </ul>
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>8.ระยะเวลาการเก็บข้อมูลส่วนบุคคล</div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทจะจัดเก็บข้อมูลส่วนบุคคลไว้ในระยะเวลาเท่าที่จำเป็น
              เพื่อดำเนินการตามวัตถุประสงค์ที่ระบุไว้
              หรือจนกว่าบริษัทจะได้รับแจ้งการขอเพิกถอนความยินยอมการเก็บข้อมูลส่วนบุคคลจากเจ้าของข้อมูลส่วนบุคคล
              หรือเพื่อเป็นการคุ้มครองสิทธิประโยชน์ของบริษัท
              เว้นแต่กฎหมายจะกำหนดหรืออนุญาตให้จัดเก็บไว้ได้นานกว่านั้น
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>
              9.การปรับปรุงแก้ไขรายละเอียดเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคล
            </div>
            <br />
            <div style={BODY_2_TH}>
              บริษัทขอสงวนสิทธิในการปรับปรุงให้ทันสมัย แก้ไข และเปลี่ยนแปลง
              รายละเอียดเกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลทั้งหมดหรือบางส่วนได้ตลอดเวลา
              ซึ่งถือเป็นดุลยพินิจของบริษัทแต่เพียงฝ่ายเดียว
              โดยการเปลี่ยนแปลงจะมีผลทันทีหลังจากที่การเปลี่ยนแปลงได้ถูกประกาศบนเว็บไซต์นี้
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>10.กฎหมายที่ใช้บังคับ</div>
            <br />
            <div style={BODY_2_TH}>
              นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้จะถูกควบคุมและตีความตามกฎหมายที่บังคับใช้ของประเทศไทย
              ข้อโต้แย้งใดๆ ที่เกี่ยวกับเว็บไซต์นี้ รวมถึงสิทธิ ข้อผูกพัน
              และการดำเนินการทั้งหมดที่อยู่ภายใต้นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้
              จะถูกส่งไปยังศาลที่มีเขตอำนาจพิจารณาคดีแห่งราชอาณาจักรไทย
            </div>
            <br />
            <br />
            <div style={HEADER_2_TH}>11.ติดต่อเรา</div>
            <br />
            <div style={BODY_2_TH}>
              หากเจ้าของข้อมูลส่วนบุคคลมีข้อสอบถาม ข้อเสนอแนะ ข้อร้องเรียน
              เกี่ยวกับนโยบายคุ้มครองข้อมูลส่วนบุคคล และ/หรือ
              แจ้งความประสงค์ในการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคล
              โปรดติดต่อมายัง
              <br />
              <br />
              <div style={BODY_2_EN}>
                FACEBOOK.COM/OUAYUN
                <br />
                LINE ID: @OUAYUN_OSOTH
              </div>
            </div>
            <br />
            <br />
            <br />
            <div style={BODY_2_TH}>
              นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้มีผลใช้บังคับตั้งแต่วันที่ 26
              เมษายน 2564 V.3.1.1
            </div>
          </Col>
        </Row>
      </Container>

      <div style={SPACE_BETWEEN_CONTENT} />
    </main>
  );
};

export default Policy;
